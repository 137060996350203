import * as Sentry from '@sentry/sveltekit'
import { version, dev } from '$app/environment'
import { init as initEnv } from './env'
import config from '$config'

initEnv(navigator.userAgent)

if (!dev) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: version,
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration(),
    ],
    beforeSend(event, hint) {
      // Filter ReCaptrcha error (https://github.com/getsentry/sentry-javascript/issues/2514)
      if (hint?.originalException === 'Timeout') return null
      // Filter VideoKit API error logs
      if (event.message?.includes('API call')) return null
      return event
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
